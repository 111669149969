import $ from "jquery";
import helper from "./helper";

export default {
  data: null,
  getLetterValue(letter) {
    let letterValues = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "X",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "R",
      "S",
      "T",
      "U",
      "W",
      "Y",
      "Z",
    ];
    for (let j = 0; j < letterValues.length; j++) {
      if (letter === letterValues[j]) {
        return j;
      }
    }
    return -1;
  },
  validateDO(numer) {
    if (numer == null || numer.length != 9) {
      return false;
    }

    numer = numer.toUpperCase();
    let letterValues = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    function getLetterValue(letter) {
      for (let j = 0; j < letterValues.length; j++)
        if (letter == letterValues[j]) return j;
      return -1;
    }

    //Check seria
    for (let i = 0; i < 3; i++) if (getLetterValue(numer[i]) < 10) return false;
    //Check number
    for (let i = 3; i < 9; i++)
      if (getLetterValue(numer[i]) < 0 || getLetterValue(numer[i]) > 9)
        return false;

    //sprawdz cyfre controlna
    let sum;
    sum =
      7 * getLetterValue(numer[0]) +
      3 * getLetterValue(numer[1]) +
      1 * getLetterValue(numer[2]) +
      7 * getLetterValue(numer[4]) +
      3 * getLetterValue(numer[5]) +
      1 * getLetterValue(numer[6]) +
      7 * getLetterValue(numer[7]) +
      3 * getLetterValue(numer[8]);
    sum %= 10;
    if (sum != getLetterValue(numer[3])) return false;

    return true;
  },
  validateRegon(regon) {
    if (typeof regon === "number") {
      regon = regon.toString();
    } else {
      regon = regon.replace(/-/g, "");
    }

    if (regon.length !== 9) {
      return false;
    }

    const regonArray = regon.split("").map((value) => parseInt(value));
    let checkSum =
      (8 * regonArray[0] +
        9 * regonArray[1] +
        2 * regonArray[2] +
        3 * regonArray[3] +
        4 * regonArray[4] +
        5 * regonArray[5] +
        6 * regonArray[6] +
        7 * regonArray[7]) %
      11;
    if (checkSum == 10) {
      checkSum = 0;
    }
    return regonArray[8] == checkSum;
  },
  validKw(kw) {
    return /^[A-Z]{2}[0-9][A-Z]\/[0-9]{1,8}\/[0-9]{1}$/.test(kw);
  },
  validateKW(kw) {
    //Check length
    if (kw == null) {
      return false;
    }
    if (kw.length !== 15) {
      return false;
    }

    kw = kw.toUpperCase();

    //Check slashes
    if (kw[4] !== "/" || kw[13] !== "/") {
      return false;
    }

    //Check court id
    for (let i = 0; i < 2; i++) {
      if (this.getLetterValue(kw[i]) < 10) {
        return false;
      }
    }

    if (this.getLetterValue(kw[2]) < 0 || this.getLetterValue(kw[2]) > 9) {
      return false;
    }

    if (this.getLetterValue(kw[3]) < 10) {
      return false;
    }

    //Check numbers
    for (let i = 5; i < 13; i++) {
      if (this.getLetterValue(kw[i]) < 0 || this.getLetterValue(kw[i]) > 9) {
        return false;
      }
    }
    //Check checkdigit
    let sum =
      1 * this.getLetterValue(kw[0]) +
      3 * this.getLetterValue(kw[1]) +
      7 * this.getLetterValue(kw[2]) +
      1 * this.getLetterValue(kw[3]) +
      3 * this.getLetterValue(kw[5]) +
      7 * this.getLetterValue(kw[6]) +
      1 * this.getLetterValue(kw[7]) +
      3 * this.getLetterValue(kw[8]) +
      7 * this.getLetterValue(kw[9]) +
      1 * this.getLetterValue(kw[10]) +
      3 * this.getLetterValue(kw[11]) +
      7 * this.getLetterValue(kw[12]);
    sum %= 10;

    if (parseInt(kw[14]) !== sum) {
      return false;
    }
    return true;
  },
  init() {
    /* eslint-disable */
    let self = this;

    $.extend($.validator.messages, {
      required: "To pole jest wymagane.",
      remote: "Wypełnij to pole.",
      email: "Podaj prawidłowy adres email.",
      url: "Podaj prawidłowy URL.",
      dateCorrect: "Podana data jest niepoprawna",
      date: "Podaj prawidłową datę.",
      dateISO: "Podaj prawidłową datę.",
      number: "Podaj prawidłową liczbę.",
      digits: "Podaj same cyfry.",
      creditcard: "Podaj prawidłowy numer karty.",
      equalTo: "Podaj tę samą wartość ponownie.",
      extension: "Podaj wartość z prawidłowym rozszerzeniem.",
      nipPL: "Podaj prawidłowy numer NIP.",
      maxlength: $.validator.format("Podaj nie więcej niż {0} znaków."),
      minlength: $.validator.format("Podaj przynajmniej {0} znaków."),
      rangelength: $.validator.format(
        "Podaj wartość o długości od {0} do {1} znaków."
      ),
      range: $.validator.format("Podaj wartość z przedziału od {0} do {1}."),
      max: $.validator.format("Podaj wartość mniejszą bądź równą {0}."),
      min: $.validator.format("Podaj wartość większą bądź równą {0}."),
      pattern: $.validator.format("Pole zawiera niedozwolone znaki."),
      equalToIgnoreCase: "Podane adresy e-mail muszą być jednakowe",
    });
    $.validator.addMethod(
      "nip2",
      function (value, element) {
        var verificator_nip = new Array(6, 5, 7, 2, 3, 4, 5, 6, 7);
        var nip = value.replace(/[\ \-]/gi, "");
        console.log(nip.length);
        if (nip.length != 10 && nip.length != 0) {
          return false;
        } else if (nip.length != 0) {
          var n = 0;
          for (var i = 0; i < 9; i++) {
            n += nip[i] * verificator_nip[i];
          }
          n %= 11;
          if (n != nip[9]) {
            return false;
          }
        }
        return true;
      },
      "Podaj prawidłowy numer NIP"
    );
    $.validator.addMethod(
      "kw",
      function (value, element) {
        return self.validateKW(value);
        // return self.validKw(value)
      },
      "Nieprawidłowy numer KW"
    );
    $.validator.addMethod(
      "dowod_osobisty",
      function (value, element) {
        return self.validateDO(value);
      },
      "Nieprawidłowy numer dowodu osobistego"
    );
    $.validator.addMethod(
      "regon",
      function (value, element) {
        return self.validateRegon(value);
      },
      "Nieprawidłowy numer REGON"
    );
    /* eslint-enable */

    $.validator.addMethod(
      "nip",
      function (value, element) {
        return this.optional(element) || /^\d{10}$/.test(value);
      },
      "Podaj NIP w formacie xxxxxxxxxx"
    );

    $.validator.addMethod(
      "postal",
      function (value, element) {
        return this.optional(element) || /^\d{2}-\d{3}$/.test(value);
      },
      "Podaj kod w formacie xx-xxx"
    );

    $.validator.addMethod(
      "phone",
      function (value, element) {
        return this.optional(element) || /^\d{9}$/.test(value);
      },
      "Podaj numer w formacie xxxxxxxxx (9 cyfr)"
    );

    $.validator.addMethod(
      "dateCorrect",
      function (value) {
        if (!value || value.length === "") {
          return true;
        }
        let reversedDate = value.split("-").reverse().join("-");

        let regex =
          /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        return regex.test(reversedDate);
      },
      "Podana data jest błędna"
    );

    $.validator.addMethod(
      "datecheck",
      function (value) {
        return (
          value.toString().indexOf("_") === -1 &&
          value.toString().indexOf(" ") === -1
        );
      },
      "Podana data jest nieprawidłowa"
    );

    $.validator.addMethod(
      "dig",
      function (value) {
        if (value.length === 0) {
          return true;
        }
        let regex = /^[0-9]+$/;
        return regex.test(value);
      },
      "Wprowadź tylko cyfry"
    );

    $.validator.addMethod(
      "num",
      function (value) {
        if (value.length === 0) {
          return true;
        }
        let regex = /^[0-9.,]+$/;
        return regex.test(value);
      },
      "Podaj liczbę"
    );

    $.validator.addMethod(
      "num2",
      function (value) {
        if (value.length === 0) {
          return true;
        }
        let regex = /^[-+]?[0-9.,]+$/;
        return regex.test(value);
      },
      "Podaj liczbę"
    );

    $.validator.addMethod(
      "files",
      function (value, element) {
        if (
          $(element).closest("form").find('input[name="attachments[]"]')
            .length > 0
        ) {
          return true;
        } else {
          return false;
        }
      },
      "Pamiętaj o załączeniu plików :)"
    );

    $.validator.addMethod(
      "dateAfterNow",
      function (value) {
        if (!value || value.length === "") {
          return true;
        }
        try {
          let date = new Date(value);
          let now = Date.now();
          if (date > now) return true;
        } catch (e) {
          console.log(e);
        }
        return false;
      },
      "Data nie może być z przeszłości"
    );
    $.validator.addMethod(
      "dateBeforeNow",
      function (value) {
        if (!value || value.length === "") {
          return true;
        }
        try {
          let date = new Date(value);
          let now = Date.now();
          if (date < now) return true;
        } catch (e) {
          console.log(e);
        }
        return false;
      },
      "Data nie może być z przyszłości"
    );
    // $.validator.addMethod("dateFormat", function(value) {
    //   let dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/
    //
    //   if (value.match(dateReg)) {
    //     return true
    //   }
    //   return false;
    // }, 'Data nieprawidłowa');

    $.validator.addMethod(
      "equalToIgnoreCase",
      function (value, element, param) {
        return (
          this.optional(element) ||
          value.toLowerCase() === $(param).val().toLowerCase()
        );
      }
    );
    $.validator.addMethod(
      "lessThan",
      function (value, element, param) {
        return helper.parseAmount(value) <= parseFloat(param);
      },
      "Wartość musi być mniejsza niż lub równa {0}"
    );
    $.validator.addMethod(
      "greatherThan",
      function (value, element, param) {
        return helper.parseAmount(value) >= parseFloat(param);
      },
      "Wartość musi być większa niż lub równa {0}"
    );

    $.validator.addMethod(
      "lessThanField",
      function (value, element, param) {
        return (
          this.optional(element) || parseInt(value) > parseInt($(param).val())
        );
      },
      "Nieprawidłowa wartość"
    );

    $.validator.addClassRules("nip", {
      nip: true,
      nip2: true,
    });
    $.validator.addClassRules("kw", {
      kw: true,
    });

    $.validator.addClassRules("postal", {
      postal: true,
    });
    $.validator.addClassRules("files-required", {
      files: true,
    });
    $.validator.addClassRules("dateAfterNow", {
      dateAfterNow: true,
    });
    $.validator.addClassRules("dateBeforeNow", {
      dateBeforeNow: true,
    });
    $.validator.addClassRules("dig", {
      dig: true,
    });
    $.validator.addClassRules("phone", {
      phone: true,
    });

    $.validator.addClassRules("num", {
      num: true,
    });

    $.validator.addClassRules("num2", {
      num2: true,
    });

    $.validator.addClassRules("dateISO", {
      dateISO: true,
    });
    $.validator.addClassRules("datepicker", {
      datecheck: true,
    });
    $.validator.addClassRules("dateCorrect", {
      dateCorrect: true,
    });
  },
};
