import Inputmask from 'inputmask'
import $ from 'jquery'

export default {
  data: null,
  hook: function () {
    this.maskPostal()
  },
  parseAmount (val) {
    return parseFloat((' ' + val).replace(/\s+/g, '').replace(/,/g, '.'))
  },
  maskPostal: function () {
    Inputmask({
      'mask': '99-999',
      oncomplete: function (e) {
        var event = document.createEvent('HTMLEvents')
        event.initEvent('input', true, true)
        e.currentTarget.dispatchEvent(event)
        $(this).trigger('change')
      }
    }).mask('.postal')
  },
}
