import api from "./api";
// import helper from './helper'
import $ from "jquery";
require("jquery-validation");

export default {
  data: null,
  jQFormSerializeArrToJson(formSerializeArr) {
    var jsonObj = {};
    $.map(formSerializeArr, function (n) {
      jsonObj[n.name] = n.value;
    });
    return jsonObj;
  },
  error(form, data, callback) {
    form.find(".spinn").each(function (index, element) {
      $(element).prop("disabled", false);
      $(element).html($(element).data("label"));
    });

    if (
      typeof data.message !== "undefined" ||
      typeof data.error !== "undefined"
    ) {
      if (typeof data.message === "string") {
        form.find(".form-error").text(data.message);
      } else if (typeof data.error === "string") {
        form.find(".form-error").text(data.error);
      } else {
        let text = "";
        for (let property in data.error) {
          text += data.error[property][0] + "\n";
          $("input[name=" + property + "]").addClass("error");
        }
        form.find(".form-error").text(text);
      }
      form.find(".form-error").show();
    } else if (typeof data.errors !== "undefined") {
      if (typeof data.errors === "string") {
        form.find(".form-error").text(data.errors);
      } else {
        let text = "";
        for (let property in data.errors) {
          text += data.errors[property][0] + "\n";
          $('input[name="' + property + '"]').addClass("error");
        }
        form.find(".form-error").text(text);
      }
      form.find(".form-error").show();
    } else if (typeof data === "string") {
      form.find(".form-error").text(data);
    }

    if (typeof data.highlight !== "undefined") {
      for (let i = 0; i < data.highlight.length; i++) {
        $('input[name="' + data.highlight[i] + '"]').addClass("error");
      }
    }

    if (callback !== null && typeof callback !== "undefined") {
      callback(data);
    }
  },
  errorPlacement: function (error, element) {
    if ($(element).closest(".form-element").length === 1) {
      error.appendTo($(element).closest(".form-element"));
    } else {
      error.insertAfter(element);
    }
  },
  init() {
    var self = this;
    $(".ajax-form").each(function () {
      var form = $(this);
      form.validate({
        lang: "pl",
        debug: true,
        submitHandler: function () {
          api.post(
            form.attr("action"),
            form.serializeArray(),
            null,
            function (data) {
              self.error(form, data);
            }
          );
        },
        errorPlacement: self.errorPlacement,
      });
    });
  },
  postValidate: function (form, vm, callback, errorCallback, before) {
    let self = this;
    if (typeof form.attr("id") === "undefined") {
      return;
    }
    try {
      return form.validate({
        lang: "pl",
        focusInvalid: false,
        messages: {
          email_confirmation: {
            equalToIgnoreCase: "Podane adresy e-mail muszą być jednakowe",
          },
        },
        ignore: ".select2-search__field, .no-validate",
        debug: false,
        showErrors(errorMap) {
          $(".js-pane").find(".accordion-error-icon").hide();
          $(".error-text").removeClass("error-text");
          $(".form-error-sections").text("");
          for (var property in errorMap) {
            if (Object.prototype.hasOwnProperty.call(errorMap, property)) {
              let section = $(form)
                .find('*[name="' + property + '"]')
                .closest(".form-validation-panel")
                .data("section");
              $(".a-side-nav")
                .find('*[data-section="' + section + '"]')
                .addClass("error-text");
            }
          }
          if (Object.keys(errorMap).length > 0) {
            $(form)
              .find(".form-error-sections")
              .text("W formularzu wystąpiły błędy");
          }
          this.defaultShowErrors();
        },
        submitHandler: function () {
          try {
            $(".js-pane").find(".accordion-error-icon").hide();
            $(".js-pane").find("h2").css("color", "");
            if (before && before !== null && typeof before !== "undefined") {
              before(form, vm);
            }
            if (form.attr("method").toUpperCase() === "POST") {
              api.post(
                form.attr("action"),
                form.serializeArray(),
                function (data) {
                  if (
                    callback &&
                    callback !== null &&
                    typeof callback !== "undefined"
                  ) {
                    callback(data);
                  }
                },
                function (data) {
                  self.error(form, data, errorCallback);
                }
              );
            } else if (form.attr("method").toUpperCase() === "GET") {
              api.get(
                form.attr("action"),
                form.serializeArray(),
                function (data) {
                  if (callback !== null && typeof callback !== "undefined") {
                    callback(data);
                  }
                },
                function (data) {
                  self.error(form, data, errorCallback);
                }
              );
            }
          } catch (e) {
            console.log(e);
          }
        },
        invalidHandler: function () {
          window.Vue.nextTick(function () {
            if (
              typeof form !== "undefined" &&
              typeof form.find("label.error")[0] !== "undefined" &&
              $(form.find("label.error")[0]).text().length > 0
            ) {
              $("html,body").animate(
                { scrollTop: $(form.find(".error")[0]).offset().top - 200 },
                "slow"
              );
            } else if (
              typeof form !== "undefined" &&
              typeof form.find("label.error")[0] !== "undefined"
            ) {
              $("html,body").animate(
                { scrollTop: $(form.find(".error")[0]).offset().top - 200 },
                "slow"
              );
            }
          });
        },
        errorPlacement: self.errorPlacement,
      });
    } catch (e) {
      console.log(e);
    }
  },
  justValidate: function (form, task, before) {
    var self = this;

    form.validate({
      lang: "pl",
      focusInvalid: false,
      debug: false,
      showErrors(errorMap) {
        $(".js-pane").find(".accordion-error-icon").hide();
        $(".error-text").removeClass("error-text");
        $(".form-error-sections").text("");
        for (var property in errorMap) {
          if (Object.prototype.hasOwnProperty.call(errorMap, property)) {
            let section = $(form)
              .find('*[name="' + property + '"]')
              .closest(".form-validation-panel")
              .data("section");
            $(".a-side-nav")
              .find('*[data-section="' + section + '"]')
              .addClass("error-text");
          }
        }

        if (Object.keys(errorMap).length > 0) {
          $(form)
            .find(".form-error-sections")
            .text("W formularzu wystąpiły błędy");
        }
        this.defaultShowErrors();
      },
      submitHandler: function () {
        if (before && before !== null && typeof before !== "undefined") {
          before(form);
        }
        $(".js-pane").find(".accordion-error-icon").hide();
        $(".js-pane").find("h2").css("color", "");
        task(form);
      },
      errorPlacement: self.errorPlacement,
    });
  },
};
