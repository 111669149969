<template>
  <div class="d-flex align-items-center justify-content-center mt-8 mb-4 pb-2">
    <label for="sort" class="pr-6 pb-0 select-label">Sortuj według:</label>
    <div class="select" @change="emitStrategy">
      <select name="sort" id="sort" v-model="strategy">
        <option value="installments">najniższa rata</option>
        <option value="commission">najniższa prowizja</option>
        <option value="margin">najniższa marża</option>
        <option value="total_to_pay">najniższa koszt całkowity</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      strategy: "installments",
    };
  },
  methods: {
    emitStrategy(obj) {
      let self = this;
      console.log(obj);
      this.$nextTick(() => {
        self.$emit("strategyChanged", self.strategy);
      });
    },
  },
  name: "filter-sort",
};
</script>

<style lang="scss" scoped>
.select-label {
  font-size: 1.4rem;
  line-height: 2.1rem;
}
</style>
