<template>
  <div class="questionnaire">
    <img
      class="shape questionnaire-section__torquoise-shape-left"
      src="../../../public/assets/img/shapes/questionnaire-torquoise-shape-left.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__orange-shape"
      src="../../../public/assets/img/shapes/questionnaire-orange.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__orange-circles"
      src="../../../public/assets/img/shapes/questionnaire-orange-circles.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__yellow-shape"
      src="../../../public/assets/img/shapes/questionnaire-yellow.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__yellow-shape-circles"
      src="../../../public/assets/img/shapes/questionnaire-yellow-circles.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__purple-shape"
      src="../../../public/assets/img/shapes/questionnaire-purple.svg"
      alt=""
    />
    <img
      class="shape questionnaire-section__purple-circles"
      src="../../../public/assets/img/shapes/questionnaire-purple-circles.svg"
      alt=""
    />
    <form id="questionnaireForm" class="row" method="POST">
      <div class="col-lg-6">
        <div class="questionnaire__header">
          <h2 class="questionnaire__header-title pb-5">
            Porównywarka kredytów hipotecznych
          </h2>
          <p class="questionnaire__header-desc">
            Odpowiedz na pytania, byśmy mogli porównać oferty największych
            banków w Polsce i przedstawić Ci najlepsze z nich. <br /><br />Przy
            obliczaniu kosztów kredytu banki biorą pod uwagę rodzaj
            nieruchomości, jej rolę oraz to, w jaki sposób zamierzasz ją
            finansować. Wprowadzone odpowiedzi będziesz mógł później zmienić.
          </p>
        </div>
        <div class="questionnaire__form pb-5 mb-4">
          <p class="questionnaire__form-question">
            Na co chcesz przeznaczyć kredyt?
          </p>
          <div
            id="form-group1"
            class="form-element questionnaire__form-answers d-flex flex-column"
          >
            <div class="d-flex align-items-center pb-3">
              <div
                class="input-bordered-wrapper"
                :class="{
                  'input-bordered-wrapper--checked':
                    parseInt(params.property_type) === 1,
                }"
              >
                <input
                  @click="scrollUnder(1)"
                  id="flat"
                  v-model="params.property_type"
                  type="radio"
                  name="credit-for"
                  value="1"
                  required
                />
                <label for="flat">mieszkanie</label>
              </div>
            </div>
            <div class="d-flex align-items-center pb-3">
              <div
                class="input-bordered-wrapper"
                :class="{
                  'input-bordered-wrapper--checked':
                    parseInt(params.property_type) === 2,
                }"
              >
                <input
                  @click="scrollUnder(1)"
                  id="house"
                  v-model="params.property_type"
                  type="radio"
                  name="credit-for"
                  value="2"
                  required
                />
                <label for="house">dom</label>
              </div>
            </div>
            <div class="d-flex align-items-center pb-3">
              <div
                class="input-bordered-wrapper"
                :class="{
                  'input-bordered-wrapper--checked':
                    parseInt(params.property_type) === 4,
                }"
              >
                <input
                  @click="scrollUnder(2)"
                  id="building-plot"
                  v-model="params.property_type"
                  type="radio"
                  name="credit-for"
                  value="4"
                  required
                />
                <label for="building-plot">działka budowlana</label>
              </div>
            </div>
            <div class="d-flex align-items-center pb-3">
              <div
                class="input-bordered-wrapper"
                :class="{
                  'input-bordered-wrapper--checked':
                    parseInt(params.property_type) === 3,
                }"
              >
                <input
                  @click="scrollUnder(1)"
                  id="commercial-space"
                  v-model="params.property_type"
                  type="radio"
                  name="credit-for"
                  value="3"
                  required
                />
                <label for="commercial-space">lokal użytkowy</label>
              </div>
            </div>
          </div>
        </div>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            class="questionnaire__form pb-5 mb-4"
            v-if="
              [1, 2, 3].indexOf(parseInt(params.property_type)) >= 0 &&
              this.step > 0
            "
          >
            <p class="questionnaire__form-question">
              Czy zamierzasz kupić nieruchomość bezpośrednio od dewelopera?
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="d-flex align-items-center pb-3">
                <div
                  class="input-bordered-wrapper"
                  :class="{
                    'input-bordered-wrapper--checked':
                      parseInt(params.developer) === 1,
                  }"
                >
                  <input
                    @click="scrollUnder(2)"
                    id="direct-purchase-yes"
                    v-model="params.developer"
                    type="radio"
                    name="direct-purchase"
                    value="1"
                    required
                  />
                  <label for="direct-purchase-yes">tak</label>
                </div>
              </div>
              <div class="d-flex align-items-center pb-3">
                <div
                  class="input-bordered-wrapper"
                  :class="{
                    'input-bordered-wrapper--checked':
                      parseInt(params.developer) === 0,
                  }"
                >
                  <input
                    @click.once="scrollUnder(2)"
                    id="direct-purchase-no"
                    v-model="params.developer"
                    type="radio"
                    name="direct-purchase"
                    value="0"
                    required
                  />
                  <label for="direct-purchase-no">nie</label>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            id="form-group2"
            class="questionnaire__form pb-5 mb-4 form-group2"
            v-if="this.step > 1"
          >
            <p class="questionnaire__form-question">
              Ile kosztuje nieruchomość, na którą chcesz przeznaczyć kredyt?
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="questionnaire__form-answer-input">
                <div class="input-suffix input-suffix--zl">
                  <input
                    id="property-cost"
                    name="property-cost"
                    type="text"
                    class="amount"
                    v-model="params.property_value"
                    required
                    @keydown="keyDown"
                    @keyup="
                      params.property_value = params.property_value
                        .toString()
                        .replace(/^[0]+/g, '')
                    "
                    greatherThan="5500"
                    @change="scrollUnder(3)"
                    @input="checkStep(3)"
                  />
                </div>
              </div>
              <small class="questionnaire__form-additional-info"
                >możesz wpisać przybliżoną kwotę</small
              >
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            id="form-group3"
            class="questionnaire__form pb-5 mb-4"
            v-if="this.step > 2"
          >
            <p class="questionnaire__form-question">
              Jaką kwotą kredytu jesteś zainteresowany?
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="questionnaire__form-answer-input">
                <div class="input-suffix input-suffix--zl">
                  <input
                    name="credit-amount"
                    type="text"
                    class="amount"
                    v-model="params.amount"
                    required
                    @keydown="keyDown"
                    @keyup="
                      params.amount = (params.amount + '').replace(/^[0]+/g, '')
                    "
                    greatherThan="5000"
                    :max="parseFloat(params.property_value)"
                    @change="scrollUnder(4)"
                    @input="checkStep(4)"
                  />
                </div>
              </div>
              <small
                class="questionnaire__form-additional-info"
                v-if="checkAmount(params.property_value)"
                >maksymalna kwota kredytu dla nieruchomości wartej
                {{ $filters.money(parseFloat(params.property_value)) }} zł to
                {{ $filters.money(parseFloat(params.property_value) * 1) }} zł
              </small>
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            id="form-group4"
            class="questionnaire__form pb-5 mb-4"
            v-if="this.step > 3"
          >
            <p class="questionnaire__form-question d-flex align-items-center">
              Na ile lat chcesz wziąć kredyt?
              <span
                class="question-mark pointerable"
                data-html="true"
                data-tippy-placement="right"
                data-tippy-content="Banki oferują zazwyczaj kredyty hipoteczne na okres 5-35 lat."
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                    fill="#332930"
                    fill-opacity="0.7"
                  />
                </svg>
              </span>
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="questionnaire__form-answer-input">
                <div class="input-suffix input-suffix--age">
                  <input
                    name="credit-period"
                    type="text"
                    v-model="params.period"
                    max="35"
                    min="5"
                    required
                    @keydown="keyDown"
                    @keyup="
                      this.params.period =
                        Math.abs(this.params.period) >= 0
                          ? Math.abs(this.params.period)
                          : 0
                    "
                    @change="scrollUnder(5)"
                    @input="scrollUnder(5)"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            id="form-group5"
            class="questionnaire__form pb-5 mb-4"
            v-if="this.step > 4"
          >
            <p class="questionnaire__form-question d-flex align-items-center">
              Jaki typ oprocentowania preferujesz?
              <span
                class="question-mark pointerable"
                data-html="true"
                data-tippy-placement="right"
                data-tippy-content="<strong>Oprocentowanie zmienne</strong> składa się z marży banku oraz WIBOR (stawka referencyjna). Takie oprocentowanie zmienia się co <strong>3 lub 6 miesięcy</strong> w zależności od banku. W związku z tym wysokość Twoich rat również będzie mogła ulegać zmianom. <br/><br/><strong>Oprocentowanie stałe</strong> jest ustalane na jednakowym poziomie na okres <strong>minimum 5 lat</strong>. Oznacza to, że <strong>przez ten okres będziesz płacił stałą ratę niezależnie od bieżących wahań stóp procentowych</strong>."
                data-placement="bottom"
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                    fill="#332930"
                    fill-opacity="0.7"
                  />
                </svg>
              </span>
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="d-flex align-items-center pb-3">
                <div
                  class="input-bordered-wrapper"
                  :class="{
                    'input-bordered-wrapper--checked':
                      parseInt(params.type_interest) === 1,
                  }"
                >
                  <input
                    id="changing-interest"
                    v-model="params.type_interest"
                    type="radio"
                    name="type-interest"
                    value="1"
                    required
                    @change="scrollUnder(6)"
                    @input="scrollUnder(6)"
                  />
                  <label for="changing-interest">zmienne</label>
                </div>
              </div>
              <div class="d-flex align-items-center pb-3">
                <div
                  class="input-bordered-wrapper"
                  :class="{
                    'input-bordered-wrapper--checked':
                      parseInt(params.type_interest) === 2,
                  }"
                >
                  <input
                    id="constant-interest"
                    v-model="params.type_interest"
                    type="radio"
                    name="type-interest"
                    value="2"
                    required
                    @change="scrollUnder(6)"
                    @input="scrollUnder(6)"
                  />
                  <label for="constant-interest">stałe</label>
                </div>
              </div>
              <div class="d-flex align-items-center pb-3">
                <div
                  class="input-bordered-wrapper"
                  :class="{
                    'input-bordered-wrapper--checked':
                      parseInt(params.type_interest) === 0,
                  }"
                >
                  <input
                    id="no-preferences"
                    v-model="params.type_interest"
                    type="radio"
                    name="type-interest"
                    value="0"
                    required
                    @change="scrollUnder(6)"
                    @input="scrollUnder(6)"
                  />
                  <label for="no-preferences">nie mam preferencji</label>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave">
          <div
            id="form-group6"
            class="questionnaire__form pb-5 mb-4"
            v-if="this.step > 5"
          >
            <p class="questionnaire__form-question d-flex align-items-center">
              Podaj wiek najstarszego kredytobiorcy
              <span
                class="question-mark pointerable"
                data-html="true"
                data-tippy-placement="right"
                data-tippy-content="Banki oferują zazwyczaj kredyty hipoteczne dla osób w wieku 18-75 lat."
              >
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.57 0.116C4.494 0.116 5.24067 0.377333 5.81 0.899999C6.37933 1.42267 6.664 2.12267 6.664 3C6.664 3.98 6.36067 4.708 5.754 5.184C5.14733 5.65067 4.326 5.884 3.29 5.884L3.248 7.214H2.142L2.086 5.002H2.492C3.416 5.002 4.13933 4.862 4.662 4.582C5.18467 4.302 5.446 3.77467 5.446 3C5.446 2.44 5.278 1.99667 4.942 1.67C4.606 1.34333 4.15333 1.18 3.584 1.18C3.00533 1.18 2.548 1.33867 2.212 1.656C1.88533 1.964 1.722 2.38867 1.722 2.93H0.518C0.518 2.37 0.644 1.88 0.896 1.46C1.148 1.03067 1.50267 0.699333 1.96 0.465999C2.42667 0.232666 2.96333 0.116 3.57 0.116ZM2.688 10.084C2.44533 10.084 2.24 10 2.072 9.832C1.904 9.664 1.82 9.45867 1.82 9.216C1.82 8.97333 1.904 8.768 2.072 8.6C2.24 8.432 2.44533 8.348 2.688 8.348C2.92133 8.348 3.11733 8.432 3.276 8.6C3.444 8.768 3.528 8.97333 3.528 9.216C3.528 9.45867 3.444 9.664 3.276 9.832C3.11733 10 2.92133 10.084 2.688 10.084Z"
                    fill="#332930"
                    fill-opacity="0.7"
                  />
                </svg>
              </span>
            </p>
            <div
              class="form-element questionnaire__form-answers d-flex flex-column"
            >
              <div class="questionnaire__form-answer-input">
                <div class="input-suffix input-suffix--age">
                  <input
                    name="borrowers-age"
                    type="text"
                    v-model="params.age"
                    max="75"
                    min="18"
                    required
                    @keydown="keyDown"
                    @keyup="
                      this.params.age =
                        Math.abs(this.params.age) >= 0
                          ? Math.abs(this.params.age)
                          : 0
                    "
                    @change="scrollUnder(7)"
                    @input="scrollUnder(7)"
                  />
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition v-on:enter="enter" v-on:leave="leave" v-if="this.step > 6">
          <button
            id="form-group7"
            type="submit"
            class="button button--rounded questionnaire__form-button"
          >
            Przygotuj ofertę
          </button>
        </transition>
      </div>
    </form>
  </div>
</template>
<script>
import ajax from "@/services/ajax";
import $ from "jquery";
import tippy from "tippy.js";
import Velocity from "velocity-animate";

export default {
  name: "questionnaire",
  props: ["parameters"],
  data() {
    return {
      step: 0,
      params: {},
    };
  },
  watch: {
    step() {
      this.ping();
    },
    parameters() {
      if (this.parameters) {
        this.params = this.parameters;
      } else {
        this.params = {};
      }
    },
  },
  methods: {
    ping() {
      this.$forceUpdate();
      this.$nextTick(() => {
        tippy("[data-tippy-content]", { allowHTML: true });
        window.top.postMessage(
          100 + parseInt(document.body.scrollHeight) + "px",
          "*"
        );
      });
    },
    removeZero(val) {
      return val.replace(/^[0]+/g, "");
    },
    keyDown: function (e) {
      if (
        [
          8, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101,
          102, 103, 104, 105, 188, 110, 190, 37, 39, 109, 9,
        ].indexOf(e.which) === -1
      ) {
        e.preventDefault();
      }
    },
    checkAmount(amount) {
      return $.isNumeric(amount);
    },
    checkStep(num) {
      if (this.step < num) {
        this.step++;
      }
    },
    scrollUnder(num) {
      if (this.step < num) {
        this.step++;
      }

      if (this.step < 8) {
        setTimeout(() => {
          let scrollToQuestion = $(`#form-group${num}`);
          $([document.documentElement, document.body]).animate(
            {
              scrollTop: scrollToQuestion.offset().top,
            },
            50
          );
        }, 250);
      }
    },
    enter: function (el) {
      this.ping();
      Velocity(el, "fadeIn", {
        duration: 240,
        easing: "easeInBack",
      });
    },
    leave: function (el) {
      this.ping();
      Velocity(el, "fadeOut", {
        duration: 0,
        easing: "easeInBack",
      });
    },
  },
  mounted() {
    let self = this;
    if (this.parameters) {
      this.params = this.parameters;
    }
    tippy("[data-tippy-content]", { allowHTML: true });
    ajax.justValidate($("#questionnaireForm"), () => {
      self.$emit("set", self.params);
    });
  },
};
</script>
