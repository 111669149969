import { createRouter, createWebHashHistory } from "vue-router";
// import start from "@/components/pages/start.vue";
import comparer from "@/components/pages/comparer.vue";

const routes = [
  {
    path: "/:slug?",
    name: "comparer",
    component: comparer,
  },
  // {
  //   path: "/comparer",
  //   name: "comparer",
  //   component: comparer,
  // },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
