import api from './api'

const params = {
  grant_type: 'client_credentials',
  client_id: window.CONFIG.client_id,
  client_secret: window.CONFIG.client_secret
}


export default {
  check () {
    let token = {
      value: localStorage.getItem('token'),
      expires_at: localStorage.getItem('token_expires_at')
    }
    if (!token.value || !token.expires_at || Date.now() > parseInt(token.expires_at)) {
      this.authorize()
    }
  },
  authorize () {
    api.post('/oauth/token', params, (data) => {
      localStorage.setItem('token', data.data.access_token)
      localStorage.setItem('token_expires_at', Date.now() + parseInt(data.data.expires_in))
    })
  }
}
