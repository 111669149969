<template>
<!--  <Navigation />-->
  <router-view />
<!--  <Footer />-->
</template>

<script>

export default {
  name: 'App',
  data () {
    return {}
  }
}
</script>

<style>
#app, body, html, .comparer {
  min-height: 100%;
}
</style>
