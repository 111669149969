const url = window.CONFIG.api_url;

const helper = {
  before(before) {
    if (typeof before !== "undefined") {
      before();
    }
  },
  success(response, success) {
    if (typeof success !== "undefined") {
      success(response.data);
    }
  },
  failure(error, failure) {
    if (!error) {
      return;
    }
    if (error) {
      if (typeof failure !== "undefined" && failure !== null) {
        failure(error.data);
      }
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      if (typeof failure !== "undefined" && failure !== null) {
        failure("Wystąpił niespodziewany błąd");
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
      if (typeof failure !== "undefined" && failure !== null) {
        failure("Wystąpił niespodziewany błąd");
      }
    }
  },
};

export default {
  get(action, params, success, failure, before) {
    if (before) {
      helper.before(before);
    }
    console.log("url:");
    console.log(url);
    console.log("action:");
    console.log(action);
    window.Vue.http
      .get(
        url + action,
        { params: params },
        {
          emulateJSON: true,
          emulateHTTP: true,
        }
      )
      .then((response) => {
        helper.success(response, success);
      })
      .catch(function (error) {
        helper.failure(error, failure);
      });
  },
  getFlex(url, params, success, failure, before) {
    if (before) {
      helper.before(before);
    }
    window.Vue.http
      .get(
        url,
        { params: params, headers: { "X-Custom": "test" } },
        {
          emulateJSON: true,
          emulateHTTP: true,
        }
      )
      .then((response) => {
        helper.success(response, success);
      })
      .catch(function (error) {
        helper.failure(error, failure);
      });
  },
  post(action, params, success, failure) {
    let headers = {};

    if (action.indexOf("token") < 0) {
      headers = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
    }

    window.Vue.axios
      .post(url + action, params, headers)
      .then(function (response) {
        success(response);
      })
      .catch(function (error) {
        console.log(typeof failure);
        if (typeof failure === "function") {
          failure(error);
        }
      })
      .then(function () {
        // always executed
      });
    // window.Vue.http.post(url + action, params, {
    //   emulateJSON: true,
    //   emulateHTTP: true
    // }).then((response) => {
    //   helper.success(response, success)
    // }).catch(function (error) {
    //   helper.failure(error, failure)
    // })
  },
  delete(action, params, success, failure, before) {
    helper.before(before);
    window.Vue.http
      .delete(url + action, params, {
        emulateJSON: true,
        emulateHTTP: true,
      })
      .then((response) => {
        helper.success(response, success);
      })
      .catch(function (error) {
        helper.failure(error, failure);
      });
  },
};
