<template>
  <span class="spinner"></span>
</template>

<script>
export default {
  name: "spinner"
}
</script>

<style lang="scss">
.spinner {
  width: 3.5rem;
  height: 3.5rem;
  display: inline-block;
  border: 3px solid #591145;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: spin 1s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
    border-top-color: #591145;
    border-bottom-color: #591145;
    border-right-color: transparent;
    border-left-color: transparent;
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
