import { createApp } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import validator from "./services/validator";
import router from "./router";
// import Bugsnag from '@bugsnag/js'
// const bugsnagVue = Bugsnag.getPlugin('vue')
// import BugsnagPluginVue from '@bugsnag/plugin-vue'

// Bugsnag.start({
// apiKey: window.CONFIG.bugsnag_key,
// plugins: [new BugsnagPluginVue()]
// })
// window.Vue = createApp(App).use(bugsnagVue)
window.Vue = createApp(App);
window.Vue.use(router);
window.router = router;
window.Vue.use(VueAxios, axios);
window.Vue.mount("#app");

function formatMoney(n, c, d, t) {
  var s;
  var i;
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  s = n < 0 ? "-" : "";
  i = parseInt((n = Math.abs(+n || 0).toFixed(c))) + "";
  var j;
  j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
validator.init();
window.Vue.config.globalProperties.$filters = {
  money(val) {
    val = String(val).replace(/,/g, "");
    return formatMoney(val, ",", ".", " ");
  },
};
